module.exports = {
	getPromos: (locale) => {		
		const apiUrl = "/api/locale/" + locale + "/promos";
		return fetch(apiUrl)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				return data;
			});
	},
	getPromo: (locale, promoCode) => {		
		const apiUrl = "/api/locale/" + locale + "/promos/" + promoCode;
		return fetch(apiUrl)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				return data;
			});
	},
};
