import React, {useState, useEffect} from "react";
import {useRouter} from "next/router";
import Image from "next/image";
import PXGSpinnerCustom from "../shared/images/spinners/PXGSpinnerCustom";
import FullBagService from "../../services/fullBag";
import PromosService from "../../services/promos";
import gaHelpers from "../../services/googleAnalytics/gaHelpers";
import styles from "./Promos.module.scss";
function Promos(props) {
	const router = useRouter();
	const {locale} = useRouter();
	const {localeResources} = props;
	const [promos, setPromos] = useState([]);
	useEffect(() => {
		PromosService.getPromos(locale).then((results) => {
			let promoOptions = results.filter((p) => p.promo && p.promoColor == "Chrome");
			promoOptions.forEach((promoOption) => {
				let blackClubPromo = results.find(
					(p) => p.promoGeneration == promoOption.promoGeneration && p.promoColor == "Black"
				);

				if (blackClubPromo) {
					promoOption.blackClubPromo = blackClubPromo;
				}
			});

			setPromos(promoOptions);
		});
	}, []);

	function BuildNow(promo) {
		gaHelpers.pushPromoDataToGA(promo);
		FullBagService.createFullBagInstance(promo.promoCode).then(function (response) {
			if (response.fullBagGuid) {
				if (promo.fitting && promo.fitting.enabled) {
					router.push("/fitting", "/fitting", {locale: locale});
				}
			}
		});
	}

	if (promos && promos.length) {
		return (
			<div className={styles.bagOptions}>
				{promos.map((promo, index) => (
					<div key={index} className={`${styles.promoContainer} col-12 col-lg-4 mb-5 mb-sm-0 text-center`}>
						{promo.image && (
							<div className={styles.imgContainer}>
								<Image
									src={promo.image}
									aria-hidden="true"
									//priority={true}
									width="370"
									height="420"
									alt={promo.imageAlt}
								/>
							</div>
						)}
						<h2 className="pt-4">{promo.name}</h2>
						<div
							className={`${styles.PricingText} h1 color_red no-letter-spacing`}
							aria-label={`Price is ${
								promo.promo && promo.promo.currencySymbol && promo.promo.currencySymbol
							}
							${promo.promo && promo.promo.price && promo.promo.price.toLocaleString()}`}>
							<span aria-hidden="true">
								{promo.promo && promo.promo.currencySymbol && promo.promo.currencySymbol}
								{promo.promo && promo.promo.price && promo.promo.price.toLocaleString()}*
							</span>
						</div>
						<div
							className={`${styles.promoDescription} my-3`}
							dangerouslySetInnerHTML={{
								__html: promo.description,
							}}
						/>
						{/* { promo.saveAmount > 0 ? <p className="text-center">SAVE ${promo.saveAmount}</p> : ""} */}

						<button
							tabIndex="0"
							className={`${styles.outlineWhiteHoverRed} mt-3`}
							aria-label={`Build the ${promo.name}`}
							onKeyDown={(e) => {
								// for keyboard navigation
								if (e.key === "Enter") {
									BuildNow(promo);
								}
							}}
							onClick={() => BuildNow(promo)}>
							{localeResources.buildNow}
						</button>

						{promo.blackClubPromo ? (
							<>
								<h2 className="pt-4">{promo.blackClubPromo.name}</h2>
								<div
									className={`${styles.PricingText} h1 color_red no-letter-spacing`}
									aria-label={`Price is 
															${promo.blackClubPromo.promo.currencySymbol && promo.blackClubPromo.promo.currencySymbol}
															${promo.blackClubPromo.promo.price && promo.blackClubPromo.promo.price.toLocaleString()}`}>
									<span aria-hidden="true">
										{promo.blackClubPromo &&
											promo.blackClubPromo.promo.currencySymbol &&
											promo.blackClubPromo.promo.currencySymbol}
										{promo.blackClubPromo &&
											promo.blackClubPromo.promo.price &&
											promo.blackClubPromo.promo.price.toLocaleString()}
										*
									</span>
								</div>
								<button
									tabIndex="0"
									className={`${styles.outlineWhiteHoverRed} mt-3`}
									aria-label={`Build the ${promo.blackClubPromo.name}`}
									onKeyDown={(e) => {
										// for keyboard navigation
										if (e.key === "Enter") {
											BuildNow(promo.blackClubPromo);
										}
									}}
									onClick={(event) => BuildNow(promo.blackClubPromo)}>
									{localeResources.buildNow}
								</button>
							</>
						) : (
							""
						)}
					</div>
				))}
			</div>
		);
	} else {
		return (
			<div className={styles.bagOptions}>
				<PXGSpinnerCustom text={`Loading Promos`} layoutSize="200px" strokeColor="#000" />
			</div>
		);
	}
}

export default Promos;
