import React from "react";

// USAGE
//  <PXGSpinnerCustom
// 		strokeColor="#000"
// 		layoutSize="200px"
// 		text={`Loading Bags`}
// 	/>

const PXGSpinnerCustom = (props) => {
	let text = props.text;
	text == "" || text == null ? (text = "Loading") : null;
	let layoutSize = props.layoutSize;
	layoutSize == "" || layoutSize == null ? (layoutSize = "200px") : null;
	let strokeColor = props.strokeColor;
	strokeColor == "" || strokeColor == null ? (strokeColor = "#000") : null;
	return (
		<React.Fragment>
			<div className="spinnerWrapper">
				<div className="spinnerUnderlay"></div>
				<div className="loader" role="alert" aria-live="assertive" aria-label={text}>
					<span className="content">
						<span className="innerContent">{text}</span>
					</span>
					<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
						<circle cx="50" cy="50" r="45" />
					</svg>
				</div>
			</div>
			<style jsx>{`
				.spinnerWrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					position: fixed;
					top: 0;
					left: 0;
					width: 100vw;
					height: 100vh;
					z-index: 999999;
				}
				.spinnerUnderlay {
					background-color: rgba(255, 255, 255, 0.9);
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
				}
				.loader {
					width: ${layoutSize};
					height: ${layoutSize};
					margin-left: auto;
					margin-right: auto;
					position: relative;
					//top: 50%;
					//transform: translateY(-50%);
					z-index: 99;
				}
				.content {
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.innerContent {
					text-align: center;
					display: block;
					padding: 10px;
				}

				svg {
					animation: 3s linear infinite both circle__svg;
				}

				@keyframes circle__svg {
					0% {
						transform: rotateZ(0deg);
					}
					100% {
						transform: rotateZ(360deg);
					}
				}

				circle {
					animation: 2s ease-in-out infinite both circle__svg-circle;
					fill: transparent;
					stroke: ${strokeColor};
					stroke-dasharray: 285;
					stroke-linecap: round;
					stroke-width: 5px;
					transform-origin: 50% 50%;
				}

				@keyframes circle__svg-circle {
					0%,
					25% {
						stroke-dashoffset: 280;
						transform: rotate(0);
					}

					50%,
					75% {
						stroke-dashoffset: 75;
						transform: rotate(45deg);
					}

					100% {
						stroke-dashoffset: 280;
						transform: rotate(360deg);
					}
				}
			`}</style>
		</React.Fragment>
	);
};
export default PXGSpinnerCustom;
